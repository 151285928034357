import { hasValue } from '@helpers/operand';
import { getFormDefaults } from './languages';
import { deepCopyObject } from './editorFormHelper';
import { getLanguageRouteAndPrefixByCodeAndRouteKey } from '@helpers/languages';
import { LanguageCode } from '@models/languageCode';
import { hasLength, isNotEmpty } from '@helpers/validators';

export function getValueFromEvent(field, event) {
    let value = '';

    switch (field.type) {
        case 'checkbox': {
            value = event.target.checked;
            break;
        }
        case 'fileSelect':
        case 'status':
        case 'galleryImage':
        case 'galleryVideo':
        case 'accordionBlock':
        case 'richText':
        case 'select': {
            value = event;
            break;
        }
        case 'publishBox': {
            value = `${event.toISOString().slice(0, -5)}Z`;
            break;
        }
        case 'VideoGallery': {
            value = event;
            break;
        }
        default: {
            value = event.target.value;
            break;
        }
    }

    return value;
}

export function getFormFieldFromId(id, allFields) {
    return allFields.find((field) => field.id === id);
}

export function decorateListBox(entity) {
    const bullet = '\u2022';
    let stringifiedList = Array.isArray(entity) ? entity.map((word) => `${bullet} ${word}\n`).toString() : null;
    return stringifiedList !== null ? stringifiedList.replaceAll('\n,', '\n') : undefined;
}

export function getFieldValue(formField, entity) {
    let value = '';

    if (
        formField.parentKey &&
        formField.key &&
        hasValue(entity[formField.parentKey]) &&
        hasValue(entity[formField.parentKey][formField.key])
    ) {
        value = entity[formField.parentKey][formField.key];
    } else if (!formField.parentKey && formField.key && hasValue(entity[formField.key])) {
        value = entity[formField.key];
    }

    if (formField.type === 'multiTagSelect' && value === '') {
        value = [];
    }

    if (formField.type === 'checkbox') {
        value = !!value;
    }

    if (formField.key === 'hreflangGroupName' && value === '') {
        value = 'None';
    }

    return value;
}

export function getFilteredList(lists, field, entityState) {
    function fieldDefinesFieldValueBasedFilter(field) {
        return typeof field.listFilterOnField !== 'undefined' && field.listFilterListItemKey;
    }

    function fieldDefinesCallbackBasedFilter(field) {
        return typeof field.listFilterCallback !== 'undefined';
    }

    function getFilteredListItemsByFieldValue(list, entityState) {
        return (
            (list?.length &&
                list.filter((item) => item[field.listFilterListItemKey] === entityState[field.listFilterOnField])) ||
            []
        );
    }

    function getFilteredListItemsByCallback(list, field) {
        return (list && field.listFilterCallback(list)) || [];
    }

    if (lists && field.list && field.list in lists) {
        let list = lists[field.list];
        if (fieldDefinesFieldValueBasedFilter(field)) {
            return getFilteredListItemsByFieldValue(list, entityState);
        }

        if (fieldDefinesCallbackBasedFilter(field)) {
            return getFilteredListItemsByCallback(list, field);
        }

        return list;
    }

    return [];
}

export function getValidationRules(allFields) {
    const rules = {};

    allFields.forEach((field) => {
        const validators = [];

        if (field.required) {
            validators.push(isNotEmpty(`${field.name} is required`));
        }

        if (field.requiredCondition) {
            const conditionallyRequiredValidator = (value, values) =>
                values && field.requiredCondition(values) && !hasValue(value) ? `${field.name} is required` : null;
            validators.push(conditionallyRequiredValidator);
        }

        if (field.maxLength) {
            validators.push(
                hasLength({ max: field.maxLength }, `${field.name} may not exceed ${field.maxLength} characters`)
            );
        }

        if (field.validators) {
            validators.push(...field.validators);
        }

        if (validators.length) {
            rules[field.id] = validators;
        }
    });

    return rules;
}

export function applyLanguageBasedDefaults(formObject, languageId) {
    const defaultProperties = getFormDefaults(languageId);
    const newFormObject = deepCopyObject(formObject);

    for (const propertyKey in defaultProperties) {
        if ('undefined' !== typeof newFormObject[propertyKey]) {
            newFormObject[propertyKey] = defaultProperties[propertyKey];
        }
    }

    return newFormObject;
}

function ensuresNoTrailingSpaces(string) {
    return string.trimRight();
}

export function handleValueToLowerCaseOnChange(event) {
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;
    event.target.value = event.target.value.toLowerCase();
    event.target.setSelectionRange(start, end);
}

export function getSanitizedFormInput(submittingData, formHelperModel) {
    const configurationFunctions = { autoTrim: ensuresNoTrailingSpaces };

    function formattedListBox(entity) {
        if (typeof entity === 'string') {
            const replaceNextLine = entity.replaceAll('\n', '');
            const splitList = replaceNextLine.split('\u2022 ');
            const formattedList = splitList.filter((word) => word !== '');
            return formattedList;
        } else {
            return entity;
        }
    }

    const sanitizedSubmittingData = { ...submittingData };

    formHelperModel.fields.forEach((fieldObject) => {
        for (let functionKey in configurationFunctions) {
            const fieldHasConfigurationFunction =
                fieldObject.sanitizationConfiguration && fieldObject.sanitizationConfiguration[functionKey];

            const configurationFunction = configurationFunctions[functionKey];
            if (fieldHasConfigurationFunction) {
                const parentKeyFieldExists =
                    fieldObject.parentKey &&
                    submittingData[fieldObject.parentKey] &&
                    submittingData[fieldObject.parentKey][fieldObject.key] != null;
                const fieldExists = submittingData[fieldObject.key] != null;

                if (parentKeyFieldExists) {
                    const fieldValue = configurationFunction(submittingData[fieldObject.parentKey][fieldObject.key]);
                    sanitizedSubmittingData[fieldObject.parentKey][fieldObject.key] = fieldValue;
                } else if (fieldExists) {
                    const fieldValue = configurationFunction(submittingData[fieldObject.key]);
                    sanitizedSubmittingData[fieldObject.key] = fieldValue;
                }
            } else if (fieldObject.type === 'listBox') {
                const fieldValue = submittingData[fieldObject.key];
                sanitizedSubmittingData[fieldObject.key] = fieldValue ? formattedListBox(fieldValue) : null;
            }
        }
    });

    return sanitizedSubmittingData;
}

export function redirectToThankyou(
    formReturnURL,
    selectedLanguage = LanguageCode.en,
    dataLayerFormValues,
    isBlogPage = false
) {
    if (window) {
        dataLayerFormValues ? sessionStorage.setItem('dataLayerFormValues', JSON.stringify(dataLayerFormValues)) : null;
        const redirectUrl =
            formReturnURL ||
            getLanguageRouteAndPrefixByCodeAndRouteKey(
                selectedLanguage,
                isBlogPage ? 'thankYouBlogUrl' : 'thankYouUrl'
            );

        if (redirectUrl.startsWith('http://') || redirectUrl.startsWith('https://')) {
            window.location = redirectUrl;
        } else {
            window.location.pathname = redirectUrl;
        }
    }
}

export const standardFormSettingNames = {
    //TODO: Add all other shared form settings here and update references on forms and unit tests
    leadSource: 'lead-source',
    leadSourceAdditionalDetails: 'lead-source-additional-details',
};

export const standardFormFieldNames = {
    //TODO: Add all other shared form field names here and update references on forms and unit tests
    leadSource: 'leadSource',
    leadSourceAdditionalDetails: 'Lead_Source_Additional_Details__c',
};
